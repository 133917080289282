export const contentTypeTemplates = [
  "oneweb_homepage",
  "claims",
  "support",
  "brand",
  "search",
  "fund_price",
  "oneweb_product_listing",
  "product_details",
  "article_listing",
  "articles_details",
  "group_insurance_product_listing",
  "flexible",
  "sitemap_page",
  "single_page_enquiry",
  "corp_category_landing_page",
];
